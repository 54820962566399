import { itemClick } from "./itemClick";
export function addEventHandler(){
    var mnuItems = document.getElementsByClassName("linkBox");
    if (mnuItems) {
      for (var i = 0; i < mnuItems.length; i++) {
        mnuItems[i].addEventListener(
          "click",
          function (event) {
            itemClick(this, event);
          },
          false
        );
      }
    }
  }