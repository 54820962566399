import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "./sanityClient";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function Image({ props }) {
  return (
    <>
      {props?.asset && (
        <img
          src={urlFor(props.asset).url()}
          alt={props.alt}
          height={props?.height || ""}
          width={props?.width || ""}
        />
      )}
    </>
  );
}
export default Image;
