import React, { useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

const Header = () => {
  const useScript = () => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://www.browsealoud.com/plus/scripts/ba.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  };

  return (
    <div className="ba-area text-right">
      <Container>
        <Row>
          <Col>
            {useScript()}
            <div
              id="__ba_panel"
              //variant="outline-light"
              class="text-right"
            ></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
