import React from "react";
import Navmenu from "../navmenu";
import BrowseAloudMenu from "../browsealoudmenu";

const Header = ({ parentPage }) => {
  return (
    <header>
      <div className="skipLinks">
        <a
          className="sr-only sr-only-focusable"
          href="#MainContentArea"
          tabIndex="0"
        >
          Skip to content
        </a>
      </div>
      <BrowseAloudMenu />
      <Navmenu parentPage={parentPage} />
    </header>
  );
};
export default Header;
