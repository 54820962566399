import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "./sanityClient";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function Figure(props) {
  return (
    <>
      <figure>
        <img
          src={urlFor(props.image.asset)
            .width(props.image.width)
            .height(props.image.height)
            .url()}
          alt={props.image.alt}
        />
      </figure>
      <figcaption>{props.image.caption}</figcaption>
    </>
  );
}
export default Figure;
