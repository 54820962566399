/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { document } from "browser-monads";

import "../style/funka.scss";

import Header from "./header/header";
import Footer from "./layout/footer";

const Layout = ({ children, parentPage }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", function (event) {
      setMouseDown(true);
    });

    document.addEventListener("keydown", function (event) {
      setMouseDown(false);
    });
  }, []);

  return (
    <div className={mouseDown ? "mousedown background" : "background"}>
      <Header parentPage={parentPage} />
      <div className="p-0 container-fluid background">
        <main id="MainContentArea" tabindex="-1">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
