export function getItemLevel(el) {
    if (el) {
      for (var i = 0; i < el.classList.length; i++) {
        if (el.classList[i].indexOf("menuLevel") != -1) {
          var len = el.classList[i].length;
          var lvlNr = el.classList[i].substring(9, len);
          return Number(lvlNr);
        }
      }
    }
    return 1;
  }