export function getStateItemId(){
    var posS = document.location.href.indexOf("menuItemClickedId");
    var len = document.location.href.length;
    if (posS != -1) {
      var id = document.location.href.substring(
        posS + "menuItemClickedId".length + 1,
        len
      );
      return id;
    }
    return "";
  }