import { showItem } from "./showItem";
export function showItems(levelClass) {
    var lvl1MnuItems = document.getElementsByClassName(levelClass);
    if (lvl1MnuItems) {
      for (var i = 0; i < lvl1MnuItems.length; i++) {
        showItem(lvl1MnuItems[i]);
      }
    } else {
      alert("Det saknas menuItems på level 1. Classname: menuLevel1");
    }
  }