import { hideItem } from "./hideItem";
export function hideAllItems(){
    var mnuItems = document.getElementsByClassName("menuItem");
    if (mnuItems) {
      for (var i = 0; i < mnuItems.length; i++) {
        hideItem(mnuItems[i]);
      }
    } else {
      console.log("Det saknas element med class: menuItem");
    }
  }