import { addEventHandler } from "./assets/addEventHandler";
import { hideAllItems } from "./assets/hideAllItems";
import { showItems } from "./assets/showItems";
import { getStateItemId } from "./assets/getStateItemId";
import { showItem } from "./assets/showItem";
import { isItemLevel1 } from "./assets/isItemLevel1";
import { getChildItemsForLevel } from "./assets/getChildItemsForLevel";
import { getItemLevel } from "./assets/getItemLevel";

export function menuHandler(){
    addEventHandler();

    //Hiding all items, showing all menuLevel1 items.
    hideAllItems();
    showItems("menuLevel1");

    //Returning the ID from the URL query
    const mnuItemId = getStateItemId();

    //Getting the element with the correct ID.
    const elActiveMenuItem = document.getElementById(mnuItemId);
    if(elActiveMenuItem) {
        //Show the correct element.
        showItem(elActiveMenuItem);

        //Checking if the element is a level1 item.
        if (isItemLevel1(elActiveMenuItem)) {
            
          //Getting the children
            var children = getChildItemsForLevel(
              elActiveMenuItem,
              "menuLevel2"
            );

            //Showing the children level2 items.
            for (var i = 0; i < children.length; i++) {
              showItem(children[i]);
            }
        }
        else {
            //Get the level of the item (1, 2 or 3?)
            var lvlNr = getItemLevel(elActiveMenuItem);
            
            //Show all on the same level as active menuItem.
            var children = getChildItemsForLevel(
              elActiveMenuItem.parentNode,
              "menuLevel" + lvlNr
            );
            for (var i = 0; i < children.length; i++) {
              showItem(children[i]);
            }

            //Show all child menuItems. lvlNr+1
            var children = getChildItemsForLevel(
              elActiveMenuItem,
              "menuLevel" + (lvlNr + 1)
            );
            for (var i = 0; i < children.length; i++) {
              showItem(children[i]);
            }

            //Show all parent menuItems
            for (var i = lvlNr; i >= 1; i--) {
              var parent = elActiveMenuItem.parentNode;
              var lvlBack = 0;
              for (
                var parent = elActiveMenuItem.parentNode;
                parent.tagName.toUpperCase() !== "LI";
                parent = parent.parentNode
              ) {
                lvlBack++;
              }

              //Next parent level, show all menuItem on that level
              showItem(parent);
              var elUL = parent.parentNode;
              for (var k = 0; k < elUL.childNodes.length; k++) {
                if (
                  elUL.childNodes[k].tagName &&
                  elUL.childNodes[k].tagName.toUpperCase() === "LI"
                ) {
                  showItem(elUL.childNodes[k]);
                }
              }
            }
          }
    } else{
        console.log("no id found");
    }
}