import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import DefaultBlockContent from "../sanity/footerBlockContent";
import "../../style/funka.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      sanityFooter {
        _rawColumnOneContent
        _rawColumnTwoContent
        _rawColumnThreeContent
      }
    }
  `);

  const footerData = data.sanityFooter;
  return (
    <Container as="footer" fluid className="footer-bg-color footer-colors">
      <Container>
        <Row className="">
          <Col className="col-12 col-md-12 col-lg-4 white">
            {footerData?._rawColumnOneContent && (
              <DefaultBlockContent blocks={footerData._rawColumnOneContent} />
            )}
          </Col>
          <Col className="col-12 col-md-6 col-lg-4 white">
            {footerData?._rawColumnTwoContent && (
              <DefaultBlockContent blocks={footerData._rawColumnTwoContent} />
            )}
          </Col>
          <Col className="col-12 col-md-6 col-lg-4 white">
            {footerData?._rawColumnThreeContent && (
              <DefaultBlockContent blocks={footerData._rawColumnThreeContent} />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
