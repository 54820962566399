export function getChildItemsForLevel(el, levelClass, children) {
    if (!children) {
      var children = new Array();
    }

    // För alla childNodes
    for (var i = 0; i < el.childNodes.length; i++) {
      var child = el.childNodes[i];

      // Ta endast med en viss levelClass
      if (levelClass) {
        for (
          var k = 0;
          child.classList && k < child.classList.length;
          k++
        ) {
          if (child.classList[k] == levelClass) {
            children.push(child);
          }
        }
      }
      // Annars ta med alla children
      else {
        children.add(child);
      }

      // har barnet barn i sin tur leta efter meny level
      if (child.childNodes.length > 0) {
        children = getChildItemsForLevel(
          child,
          levelClass,
          children
        );
      }
    }
    return children;
  }